
.bibleSection {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../../images/gitaImg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.bibleSection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Change the color and opacity as desired */
  z-index: 1;
}

.bibleSection .container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  color: white;
}

.bibleSection .container .topContent,
.bibleSection .container .topContentTwo,
.bibleSection .container .inputContainer,
.bibleSection .container .displayContainer,
.bibleSection .container .submitButtonDiv,
.bibleSection .container .instructionsContainer {
  position: relative;
}

.logoutButtonContainer{
  border-radius: 10px; 
  background-color: #7b7a7a76; 
  padding: 10px; 
  display: inline-block; 
  top: 20px;
  left: 20px;
  position: absolute;
  cursor: pointer;
}

.backButton {
  z-index: 10000;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  
}

  
  .container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* max-width: 80%; */
    /* height: 80vh; */
    /* max-height: 1000px; */
    margin: 0 auto;
    /* background-color: rgba(255, 255, 255, 0.3); */
    width: 80%;
  }
  
  .topContent {
    font-size: 22px;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: white;
  }
  
  .topContentTwo {
    font-size: 18px;
    margin: 0;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: white;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    color: white;
  }
  
  .inputContainer h3 {
    font-size: 20px;
    margin-bottom: 0px;
    color: white;
  }
  
  .inputContainer input[type="text"] {
    width: 80%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 5px;
    border-color: black;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    font-size: 18px;
    /* font-weight: 800; */
  }
  
  .displayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px
  }
  
  .displayContainer h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
  }
  
  .displayBox {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    border: 1px solid #ccc;
    /* margin-bottom: 20px; */
    /* padding: 10px; */
    font-size: 18px;
    height: 130px;
    overflow-y: auto;
    opacity: 1.0;
    color: black;
  }

  .responseCont{
    font-size: 18px;
    color: black;
  }
  
  .submitButtonDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
  
  .submitButton {
    padding: 15px 20px;
    border: none;
    background-color: red;
    color: #ffa500;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submitButton:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  }
  
  .instructionsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .instructionsContainer h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .instructionsContainer p {
    margin: 0;
    margin-bottom: 10px;
  }
  
  .instructionsContainer aside {
    font-size: 12px;
  }

  .menuButton {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url("../../images/hamburger.png") no-repeat center;
    background-size: contain;
    z-index: 1000;
  }
  
  .menu {
    position: fixed;
    top: 70px;
    right: 20px;
    width: 200px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    cursor: pointer;

  }
  
  .menu a {
    display: block;
    margin-bottom: 10px;
    color: #000;
    text-decoration: none;
  }
  
  
  @media only screen and (max-width: 550px) {
    .instructions-container aside {
      font-size: 10px;
    }
  
    .topContent {
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px
    }
  
    .topContent-two {
      font-size: 10px;
      margin: 0;
    }
    .inputContainer input[type="text"] {
      width: 80%;
      margin: 0 auto;
      /* max-width: 600px; */
      
    }

    .menuButton {
      top: 10px;
      right: 10px;
    }
  
    .menu {
      top: 50px;
      right: 10px;
    }
  }